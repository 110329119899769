import { ADD_ORDER_DETAILS, ADD_TRANSACTION_ID,DELETE_ORDER_DETAILS,DELETE_TRANSACTION_ID } from "../actions/orderActions";

const initState = [];

const orderReducer = (state = initState, action) => {
  const orderItems = state
  if(action.type===ADD_ORDER_DETAILS)
  {
    return {
        ...state,
        orderDetails: action.payload,
      };
  }
  if(action.type===ADD_TRANSACTION_ID)
    {
      return {
          ...state,
          transactionId: action.payload,
        };
    }
    if(action.type===DELETE_ORDER_DETAILS)
        {
          return {
              ...state,
              orderDetails: null,
            };
        }
        if(action.type===DELETE_TRANSACTION_ID)
          {
            return {
                ...state,
                transactionId:null,
              };
          }
   
  return orderItems;
};

export default orderReducer;

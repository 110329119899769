export const ADD_ORDER_DETAILS = "ADD_ORDER_DETAILS";
export const ADD_TRANSACTION_ID = "ADD_TRANSACTION_ID";
export const DELETE_ORDER_DETAILS = "DELETE_ORDER_DETAILS";
export const DELETE_TRANSACTION_ID = "DELETE_TRANSACTION_ID";



// add to compare
export const addOrderDetails = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Added To Compare", {
        appearance: "success",
        autoDismiss: true
      });
    }
    dispatch({ type: ADD_ORDER_DETAILS, payload: item });
  };
};

export const addTransactionId = (item, addToast) => {
    return dispatch => {
      if (addToast) {
        addToast("Added To Compare", {
          appearance: "success",
          autoDismiss: true
        });
      }
      dispatch({ type: ADD_TRANSACTION_ID, payload: item });
    };
  };

// delete from compare
export const deleteOrderDetails = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Compare", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ORDER_DETAILS, payload: item });
  };
};

export const deleteTransactionId = (item, addToast) => {
    return dispatch => {
      if (addToast) {
        addToast("Removed From Compare", {
          appearance: "error",
          autoDismiss: true
        });
      }
      dispatch({ type: DELETE_TRANSACTION_ID, payload: item });
    };
  };
  
